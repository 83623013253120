/* MaintenanceModeMessage.css */

.maintenance-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust the height as needed */
  text-align: center;
}

.maintenance-message h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.maintenance-message p {
  font-size: 16px;
  color: #888;
}
